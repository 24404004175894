<template>  

    <b-card title="Billing Backend">
            <b-spinner v-if="spinnerShow == true" style="width: 3rem; height: 3rem;" label="Large Spinner" type="grow">
            </b-spinner>
                <b-row v-if="mainData == true"> 
                    <b-col cols="6">
                        <b-form-group
                            label="Select Seller(s)"
                            label-for="select_seller"
                            description="seller_id --- company_name(brand)"
                        >
                        <v-select
                            v-model="selectedSellers"
                            placeholder="Select Sellers"
                            :options="option"
                            :key="componentKey"
                            id="select_seller"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Upload Invoice"
                            label-for="upload_invoice"
                        >
                           
                                <b-form-file
                                    v-model="upload_invoice"
                                    id="upload_invoice"
                                />
                               
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Amount"
                            label-for="amount"
                            >
                            <b-form-input
                                id="amount"
                                placeholder="Enter Amount"
                                v-model='amount'
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Payment Link"
                            label-for="payment_link"
                            >
                            <b-form-input
                                id="payment_link"
                                placeholder="Enter Payment Link"
                                v-model='payment_link'
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            label="Additional File Attachment"
                            label-for="additional_file_attachment"
                        >
                       
                            <b-form-file
                                v-model="additional_file_attachment"
                                id="additional_file_attachment"
                            />
                          
                        </b-form-group>
                    </b-col>
                    

                    
                    <br><br><br>
                    <b-col cols="12">
                        <b-button
                        variant="primary"
                        type="submit"
                        @click.prevent="sendForm"
                        >
                        Submit
                        </b-button>
                    </b-col>
                </b-row>
           
       
        
        

        <!-- basic modal -->
        <b-modal
        id="modal-1"
        title="Upload File"
        ok-only
        ok-title="Upload"
        >
        
            <b-form-file
            multiple
          
            />
        
        </b-modal>

       

    


    
    
    </b-card>

</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script type="text/javascript" src="libs/FileSaver/FileSaver.min.js"></script>
<script type="text/javascript" src="libs/js-xlsx/xlsx.core.min.js"></script>
<script type="text/javascript" src="tableExport.min.js"></script>
<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown,BCard,BButton,BTabs,BTab,BFormFile,BForm,BRow,BCol,BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import pages from '@/router/routes/pages'
import Ripple from 'vue-ripple-directive'
import reportStore from '@/store'

import VuePapaParse from 'papaparse'

export default {
  components: {
    VueGoodTable,
    vSelect,
    BSpinner,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormFile,
    BTab,
    BTabs,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BButton,
    VuePapaParse,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      upload_invoice:[],
      additional_file_attachment:[],
      file:'',
      payment_link:'',
      amount:'',
      selectedSellers:[],
      option:[],
      isLoadingProp: false,
      datas:[],
      componentKey:0,
      mainData:true,
      spinnerShow:false
    }
  },
  computed: {
    

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

   created() {
    this.$http.post('get-sellers-all')
        .then(res => { 
        if(res.status == 200){
            for(var i=0;i<res.data.data.length;i++){
                // console.log(res.data.data)
                this.option[i] = res.data.data[i].id+' --- '+res.data.data[i].company_name+'('+res.data.data[i].brand+')';
            }
            this.componentKey = 1;
        }
    })
          
  },
  
  methods: {
    sendForm(){
        var formData = new FormData();
        formData.append('payment_link', this.payment_link);
        formData.append('upload_invoice', this.upload_invoice);
        formData.append('additional_file_attachment', this.additional_file_attachment);
        formData.append('selectedSellers', this.selectedSellers);
        formData.append('amount', this.amount);
        this.mainData = false;
        this.spinnerShow = true;
        this.$http.post('seller-billing', formData, {
            headers: {
            'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            // this.showTable = false;
            this.mainData = true;
            this.spinnerShow = false;

            if(res.data.error == '0'){
                this.upload_invoice = [];
                this.additional_file_attachment = [];
                this.selectedSellers = [];
                this.payment_link = '';
                this.amount = '';
            }
            this.toast((res.data.error == '1') ? 'danger' : 'success', res.data.message, 'Seller Billing Process'); 
        })
    },

    toast(variant = null, message = 'Toast body content', title = variant) {
				
        message = this.$createElement('strong', { class: 'mr-2' }, message)

        this.$bvToast.toast(message, {
            title: title,
            variant,
            solid: false,
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>